<template>
    <v-expansion-panel id="exPan" class="mb-2" readonly>
        <v-row align="center" dense justify="center" no-gutters>
            <v-hover v-slot:default="{ hover }">
                <v-col class="mb-2" cols="12">
                    <v-row align="center" dense justify="center" no-gutters>
                        <v-col>
                            <v-expansion-panel-header id="ExPanHeader" style="height: 60px">
                                <template v-slot:actions>
                                    <v-btn class="icon" icon>
                                        <v-icon @click.self="toggleOpenCloseState">
                                            {{ getOpenCloseHandleIcon }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:default>
                                    <v-row align="center" class="header ms-0">
                                        <v-col>
                                            <v-row align="center">
                                                <v-btn class="drag-handle" icon>
                                                    <v-icon>mdi-arrow-up-down</v-icon>
                                                </v-btn>
                                                <v-text-field
                                                    :value="page.title" class="header font-weight-bold" flat
                                                    hide-details placeholder="Enter page title (optional)"
                                                    solo @input="updateTitle">
                                                </v-text-field>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-expansion-panel-header>
                        </v-col>
                        <v-col class="py-0" cols="auto">
                            <kurcc-form-spacer class="pa-0">
                                <v-container v-if="hover">
                                    <v-btn icon @click="deletePage">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="clonePageAfter">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                </v-container>
                            </kurcc-form-spacer>
                        </v-col>
                    </v-row>
                </v-col>
            </v-hover>
            <v-col cols="12">
                <v-expansion-panel-content id="innerExPan">
                    <v-row v-if="!pageHaveQuestions" dense no-gutters>
                        <v-col cols="12">
                            <kurcc-empty-question-page-action-bar :pageIndex="pageIndex"/>
                        </v-col>
                    </v-row>
                    <v-row dense no-gutters>
                        <v-col cols="12">
                            <kurcc-question-list v-model="questions" :pageIndex="pageIndex"/>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-col>
        </v-row>
    </v-expansion-panel>
</template>

<script>
import {QuestionType} from "@/modules/forms/enums/question-type";
import {
    CLONE_INSPECTION_FORM_PAGE_AFTER,
    DELETE_INSPECTION_FORM_PAGE,
    UPDATE_INSPECTION_FORM_PAGE_FIELD,
    UPDATE_INSPECTION_FORM_PAGE_QUESTIONS
} from "@/modules/app/store/mutation-types";

export default {
    name: "KurccQuestionPage",
    components: {
        KurccFormSpacer: () => import('@/modules/forms/components/KurccFormSpacer'),
        KurccQuestionList: () => import('@/modules/forms/components/question/KurccQuestionList'),
        KurccEmptyQuestionPageActionBar: () => import('@/modules/forms/components/KurccEmptyQuestionPageActionBar')
    },
    props: {
        pageIndex: {
            type: Number,
            required: true
        },
        pageHaveQuestions: {
            type: Boolean,
            required: true
        },
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            QuestionType
        }
    },
    computed: {
        page() {
            return this.$store.state.forms.form.pages[this.pageIndex]
        },
        questions: {
            get() {
                return this.$lodash.cloneDeep(this.page.questions)
            },
            set(questions) {
                this.$store.commit(UPDATE_INSPECTION_FORM_PAGE_QUESTIONS, {pageIndex: this.pageIndex, questions})
            }
        },
        getOpenCloseHandleIcon() {
            return this.isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right'
        }
    },
    methods: {
        updateTitle(value) {
            this.$store.commit(UPDATE_INSPECTION_FORM_PAGE_FIELD, {pageIndex: this.pageIndex, field: 'title', value})
        },
        deletePage() {
            this.$store.commit(DELETE_INSPECTION_FORM_PAGE, {
                pageIndex: this.pageIndex
            })
        },
        clonePageAfter() {
            this.$store.commit(CLONE_INSPECTION_FORM_PAGE_AFTER, {
                pageIndex: this.pageIndex,
            })
        },
        toggleOpenCloseState() {
            this.isOpen ? this.closePanel() : this.openPanel()
        },
        openPanel() {
            this.$emit('open-panel', this.pageIndex)
        },
        closePanel() {
            this.$emit('close-panel', this.pageIndex)
        }
    },
    created() {
        this.openPanel()
    }
}
</script>

<style lang="scss" scoped>
.icon {
    order: 0;
}

.header {
    order: 1;
}

</style>

<style lang="scss">
#innerExPan .v-expansion-panel-content__wrap, #ExPanHeader {
    padding: 0 !important;
}

#exPan {
    &:before {
        box-shadow: none !important;
    }
}

.rotate-45-right:before {
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
